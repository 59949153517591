.heartbeat {
    animation: heartbeat 1.38s infinite;
}

@keyframes heartbeat {

    0%,
    100% {
        transform: scale(1);
        animation-timing-function: ease-out;
    }

    /* 14%,
    28%,
    42%, */
    70% {
        transform: scale(1.2);
        animation-timing-function: ease-in;
    }
}