@keyframes fade_in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes grow {
	0% {
		transform: scale(1); /* Initial size */
	}
	100% {
		transform: scale(1.5); /* Grown size */
	}
}
@keyframes grow_shrink {
	0% {
		transform: scale(1); /* Initial size */
	}
	50% {
		transform: scale(1.5); /* Grown size */
	}
	100% {
		transform: scale(1); /* Grown size */
	}
}

@keyframes lightning_effect {
	0% {
		transform: scaleY(1) scaleX(1);
		opacity: 0.2;
	}
	20% {
		transform: scaleY(1.7) scaleX(1.8);
		opacity: 1;
	}
	40% {
		transform: scaleY(1.3) scaleX(1.2);
		opacity: 0.6;
	}
	60% {
		transform: scaleY(1.5) scaleX(1.8);
		opacity: 1;
	}
	80% {
		transform: scaleY(1.1) scaleX(1);
		opacity: 0.4;
	}
	90% {
		transform: scaleY(1.5) scaleX(1.5);
		opacity: 0.8;
	}
	100% {
		transform: scaleY(1.1) scaleX(1);
		opacity: 0.4;
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg) scale(1);
	}
	100% {
		transform: rotate(360deg) scale(1);
	}
}

@keyframes rotate_large {
	0% {
		transform: rotate(0deg) scale(1.5);
	}
	100% {
		transform: rotate(360deg) scale(1.5);
	}
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

textarea::placeholder {
	font-family: Arial, sans-serif;
	color: #adacb0;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: -0.05px;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.title {
	cursor: 'default';
	font-family: 'arial';
	font-size: '12px';
	line-height: '24px';
	color: '#345247';
	margin-bottom: '8px';
	font-weight: 550;
}
